<template >
  <b-container fluid>
    <loading :active.sync="isLoading" :is-full-page="true"></loading>
    <b-row align-h="center" style="margin-bottom:10px;"><center><h3>Consulta de adeudos, Pago en línea y Facturación</h3></center></b-row>
    <div style="margin-left:-25px; margin-right:-25px;">
      <iframe scrolling="no" style="width:100%; height:2400px; " v-on:load="load" :src="'https://siprem-221621.appspot.com/'+this.getAliasClient(this.$route.params.municipio)+'/portalcontribuyente'"></iframe>
    </div>
  </b-container>
</template>

<script>
  import Loading from 'vue-loading-overlay';

  export default {
    components: {
      Loading
    },
    data() {
      return {
        isLoading:true
      }
    },
    mounted(){
      window.scrollTo(0,0);
    },
    methods: {
      load(){
        this.isLoading=false;
      },
      getAliasClient(codeClient){
        if(codeClient=='MC200'){
          return 'demo';
        }else if(codeClient=='MC089'){
          return 'teotihuacan';
        }else if(codeClient=='ED001'){
          return 'edudemo';
        }else if(codeClient=='MC080'){
          return 'acolman';
        }else if(codeClient=='MC002'){
          return 'coyotepec';
        }else{
          return 'none';
        }
      }
    },
    validations: {
        
    }
  }
</script>
<style>

</style>